$(document).on('submit', ".subscribe-js", function(e) {
    e.preventDefault();
    var form = $(this).closest('form');
    
    var gaEventData = {
        gcat: form.data('gcat'),
        gact: form.data('gact-try'),
        glabel: form.data('glabel') + location.href
    }
    app.fn.triggerGaEvent(gaEventData);
    
    var dataTarget = form.data('target');
    if (dataTarget === 'app.alert') {
        app.alert.addClass('wbg');
    }
    form.find('input').each(function() {
        app.fn.removeFormError($(this));
    });
    var data = form.serializeObject();
    data[app.csrf.name] = app.csrf.value;
    $.ajax({
        url: form.attr('action'),
        data: data
    }).then(function(msg) {
        if (app.fn.checkMsg({
            param: [],
            msg: msg
        })) {
            if (msg.isError) {
                if (typeof(msg['errors']) === 'string') {
                    app.fn.alert({title: err.error, message: msg['errors']});
                } else if (typeof(msg['errors']) === 'object') {
                    try{
                        app.fn.makeFormErrors(form, msg.errors);
                    } catch (err) {
                        app.fn.alert({title: err.error, message: err.err_format});
                    }
                } else {
                    app.fn.alert({title: err.error, message: err.err_format});
                }
            } else {
                
                var gaEventData = {
                    gcat: form.data('gcat'),
                    gact: form.data('gact-success'),
                    glabel: form.data('glabel-success') + location.href
                }
                app.fn.triggerGaEvent(gaEventData);
                
                if (dataTarget === 'app.alert') {
                    $('.ui-dialog-title').text(mess['subscribeTitleOk']);
                    $('#alert').html('<div class="text">' + mess['subscribeTextOk'] + '</div>');
                    app.alert.focus();
                } else {
                    form[0].reset();
                }
            }
        }
    }).always(function() {
        if (dataTarget === 'app.alert') {
            app.alert.removeClass('wbg');
        }
    });
});
