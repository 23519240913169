$(window).on('socialLikesLoaded', function() {
    var socialBlock = $('.social-share-js');
    
    socialBlock.find('>li>ul').socialLikes({
        counters: false
    });
    
    socialBlock.find('.btn.opener').on('click', function(e) {
        e.preventDefault();
        app.fn.scrollToElmId('comments');
//        socialBlock.toggleClass('active');
    });
});
