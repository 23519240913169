(function() {
    // var subscribeURI = app.push.url,
    // pushServerKey = app.push.key,
    var swPath = "/sw.js";

    //Firefox if click Not Now button (message is denied) set permission status to default
    //after some attempt we know that user don't allow permission
    // var countAttemptGetPermission = 0,
    //     maxCountAttemptGetPermission = 3,
    //     breakStatus = false;

    var isBrowserCompatible = checkBrowserCompatibility();

    // setTimeout(function () {
    //     if (isBrowserCompatible) {
    //         initPermissionRequest(Notification.permission);
    //     }
    // }, 10);

    // function initPermissionRequest(permission) {
    //     if (breakStatus) {
    //         return;
    //     }
    //     if (permission === 'denied') {
    //         breakStatus = true; //breaks recursive calls
    //         swRegister();
    //     } else if (permission === 'granted') {
    //         breakStatus = true;
    //         swRegister();
    //     } else if (permission === 'default') {
    //         Notification.requestPermission().then(function (gettedPermission) {
    //             countAttemptGetPermission++;
    //             if (countAttemptGetPermission >= maxCountAttemptGetPermission) {
    //                 initPermissionRequest('denied');
    //             }
    //             initPermissionRequest(gettedPermission);
    //         });
    //     }
    // }

    function swRegister() {
      if (isBrowserCompatible) {
        navigator.serviceWorker.register(swPath, { scope: '/' })
        .then(
          function () {},
          function (e) {console.log(e);}
        );
      } else {
        console.log('Browser is not compatible with Service Workers');
      }
    }

    // function checkSubscription() {
    //     navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
    //         return serviceWorkerRegistration.pushManager.getSubscription();
    //     }).then(function (subscription) {
    //         if (!subscription) {
    //             push_subscribe();
    //         } else {
    //             push_updateSubscription();
    //         }
    //     }).catch(function (e) {
    //         console.log('Error check the subscription: ', e);
    //     });
    // }

    // function push_updateSubscription() {
    //     navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
    //         return serviceWorkerRegistration.pushManager.getSubscription();
    //     }).then(function (subscription) {
    //         return push_sendSubscriptionToServer(subscription, 'PUT', false);
    //     }).catch(function (e) {
    //         console.log('Error when updating the subscription', e);
    //     });
    // }

    // function push_subscribe() {
    //     navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
    //         return serviceWorkerRegistration.pushManager.subscribe({
    //             userVisibleOnly: true,
    //             applicationServerKey: urlBase64ToUint8Array(pushServerKey)
    //         });
    //     }).then(function (subscription) {
    //         return push_sendSubscriptionToServer(subscription, 'PUT', true);
    //     }).catch(function (e) {
    //         if (Notification.permission === 'denied') {
    //             console.log('Notifications are denied by the user.');
    //         } else {
    //             console.log('Impossible to subscribe to push notifications', e);
    //         }
    //     });
    // }

    // function push_sendSubscriptionToServer(subscription, method, isNew) {
    //     var key = subscription.getKey('p256dh');
    //     var token = subscription.getKey('auth');
    //     var contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0];

    //     return fetch(subscribeURI, {
    //         method: method,
    //         body: JSON.stringify({
    //             endpoint: subscription.endpoint,
    //             publicKey: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
    //             authToken: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
    //             contentEncoding: contentEncoding,
    //             isNew: isNew
    //         })
    //     }).then(function (res) {
    //         return res.json();
    //     });
    // }

    // function push_unsubscribe() {
    //     navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
    //         return serviceWorkerRegistration.pushManager.getSubscription();
    //     }).then(function (subscription) {
    //         if (!subscription) {
    //             return;
    //         }
    //         return push_sendSubscriptionToServer(subscription, 'DELETE', false);
    //     }).then(function (subscription) {
    //         subscription.unsubscribe();
    //     }).catch(function (e) {
    //         console.log('Error when unsubscribing the user', e);
    //     });
    // }

    // //service function
    // function urlBase64ToUint8Array(base64String) {
    //     var padding = '='.repeat((4 - base64String.length % 4) % 4);
    //     var base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

    //     var rawData = window.atob(base64);
    //     var outputArray = new Uint8Array(rawData.length);

    //     for (var i = 0; i < rawData.length; ++i) {
    //         outputArray[i] = rawData.charCodeAt(i);
    //     }
    //     return outputArray;
    // }

    function checkBrowserCompatibility() {
        if (!('serviceWorker' in navigator)) {
            console.log("Service workers are not supported by this browser");
            return false;
        }

        // if (!('PushManager' in window)) {
        //     console.log('Push notifications are not supported by this browser');
        //     return false;
        // }

        // if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
        //     console.log('Notifications are not supported by this browser');
        //     return false;
        // }

        return true;
    }
    swRegister();
})();

