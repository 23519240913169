var goTop = $('.go-top-js');

$(window).on('scrollWindow', function() {
    if (app.scroll > 100) {
        goTop.addClass('active');
    } else {
        goTop.removeClass('active');
    }
});

goTop.on('click', function() {
    $('html, body').animate({scrollTop: 0}, 300);
});
