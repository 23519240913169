// import socialWidgets from './social_widgets/loadWidgets.js';

export var initMainScript = function () {

    (function ($) {
        'use strict';

        require('./common/upload');  //$.fn.upl
        require('./common/commonFunctions');  //app.fn.waitElmExists
        require('./alert/main');

         require('./common/commonVars');
         require('./forms/formErrors');

        $(function () {
            require('./common/common');

            if ($('textarea.autosize-js').length) {
                require('./autosize/index');
            }
            if ($('.auth-js').length) {
                require('./login/loginOpener');
                require('./login/loginCommon');
            }


//main menu
            if ($('ul.main-menu').length) {
                require('./common/mainMenu');
            }
//main menu

//search window
            if ($('.search-overlay').length) {
                require('./common/searchWindow');
            }
//search window

//scroll to top
            if ($('.go-top-js').length) {
                require('./common/scrollToTop');
            }
//scroll to top

//subscribe
            if ($('.subscribe-opener-js').length) {
                require('./subscribe/subscribeOpener');
            }

            require('./subscribe/subscribeCommon');
//subscribe

//main show more button
            if ($('.show-more-js').length) {
                require('./common/showMoreAtMain');
            }
//main show more button

            if ($('.social-likes-inline-js').length || $('.social-share-js').length) {
                require('./socialLikes/getScript');
                if ($('.social-likes-inline-js').length) {
                    require('./socialLikes/inlineButtons');
                }
                if ($('.social-share-js').length) {
                    require('./socialLikes/sidedSocialLikes');
                }
            }

            // require('./common/googleAnalitycs');

            if ($('.filter-rubrics-js').length) {
                require('./rubricsFilter/positioningBlock');
                require('./rubricsFilter/rubrics');
                app.fn.initRubrics();
            }

            if ($('.all-rubric-js').length) {
                require('./rubricsAll/index');
            }

            if ($('.w-comments-js').length) {
                require('./comments/index');
                require('./comments/commentAnswer');
            }

            if (!app.debug) {
                require('./sw/regSw');
            }

            require('./googleAnalitycs/getScript');

            // googlePlayInvite();

            if ($('.w-left-block-js').length) {
                require('./rubricsFilter/positioningBlock');
            }

			      if (!app.debug) {
			      // if (app.debug) {
	            socialWidgets();
			      }

            require('./common/windowLoad');     //must be last

        });
    })(jQuery)

};

app.fn.waitUntilLoad(['jQuery'], function () {
    initMainScript();
});

