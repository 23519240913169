//template for cloning rubric block
var tmpl;

//array of selected rubrics id
var selected = [];

//array of array of rubrics that must be opened in tray
var opened = [];

//service chain to build opened
var mapChain = [];

//max levels in each level 1
var maxLevel = {};

app.fn.addLevelRubric = function(rubric, level, parentId, root) {
    if (!$.isArray(rubric)) {
        level++;
        $.each(rubric, function(i, v) {
            var clone = tmpl.clone();
            var input = clone.find('input[type="checkbox"]');

            if (selected.indexOf(v.id) !== -1) {
                clone.find('#chb').prop('checked', true);
            }

            clone
                    .addClass('level-' + level)
                    .attr('data-level', level)
                    .attr('data-parent', parentId)
                    .removeClass('template tmpl-js')
                    .find('#chb')
                        .attr('id', 'chb_' + v.id )
                        .attr('value', v.id)
            ;
            clone.find('.w-cb label').attr('for', 'chb_' + v.id );
            clone.find('[data-role="name"]').text(v.name);
            clone.find('[data-role="count-news"]').text(v.countNews);
            if ($.isArray(v.data)) {
                clone.find('.w-icon').remove();
            }

            if (opened.indexOf(v.id) !== -1) {
                clone.attr('data-hidden', 1);
            } else {
                clone.attr('data-hidden', 0);
            }

            if (level === 1) {
                root.append(clone);
            } else {
                var children = root.find('>li>.w-children');
                    children.attr('data-parent', parentId);
                    children.append(clone);
            }

            clone.find('.name').on('click', function() {
                input.trigger('click');
            });

            var icon = clone.find('.w-icon');
            if (icon.length) {
                icon.on('click', function() {
                    app.fn.rubricTrigger(clone, null);
                });
            }

            input.on('change', function() {
                var value = $(this).val();
                var wrapper = $(this).closest('.w-rubric');
                if ($(this).is(':checked')) {
                    app.fn.saveToSelected(value, 'save');
                    app.fn.changeChildrenState(wrapper, 'check');
                    app.fn.rubricTrigger(wrapper, 'show');
                } else {
                    app.fn.saveToSelected(value, 'remove');
                    app.fn.changeChildrenState(wrapper, 'uncheck');
                }
                app.fn.markParents(wrapper);
                Cookies.set('selected', JSON.stringify(selected), 1/24);
                app.fn.reloadOnChange();
            });

            app.fn.addLevelRubric(v.data, level, v.id, clone);

            app.fn.rubricTrigger(clone, null);
        });
    }
}

app.transitionInProgress = false;

app.fn.reloadOnChange = function() {
    clearTimeout(window.reload);
    window.reload = setTimeout(function() {
//console.log('---------send AJAX: ' + app.transitionInProgress);
        if (!app.transitionInProgress) {
            app.transitionInProgress = true;

            var loader = $('.w-loader-s');
            $('.content-place-js').addClass('loading');
            loader.addClass('work');
            
            
            var data = {};
            data[app.csrf.name] = app.csrf.value;
            $.ajax({
//                url: location.href,
                url: $('link[rel="canonical"]').attr('href'),
                data: data
            }).then(function(msg) {
//console.log('then begin: ' + app.transitionInProgress);
                if (app.fn.checkMsg({param: [],msg: msg})) {
                    if (msg.isError) {
                        app.fn.alert({title: err.error, message: msg['errors']});
                    } else if (typeof(msg.redirect) !== 'undefined'){
                        location.href = msg.redirect;
                    } else {
                        var newContent = $(msg.html).find('.content-place-js');
                        var newPaginator = $(msg.html).find('.paginator-place-js');
                        newContent.addClass('loading');
                        $('.content-place-js').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function() {
                            $('.content-place-js').replaceWith(newContent);
                            $('.paginator-place-js').replaceWith(newPaginator);
                            setTimeout(function() {
                                newContent.removeClass('loading');
                                loader.removeClass('work');
                                app.LazyLoad.update();
                                app.transitionInProgress = false;
                                $("html, body").stop().animate({scrollTop: app.leftPanel.offset.top - app.main_menu.height}, 300, 'swing');
//console.log('change state: ' + app.transitionInProgress);
                            }, 0);
                        });
                    }
                }
                clearTimeout(app.hangUp);
                app.hangUp = setTimeout(app.fn.onHangUp = function() {
                    var newContent = $('.content-place-js');
                    if (newContent.hasClass('loading')) {
//console.log('remove hang up: ' + app.transitionInProgress);
                        newContent.trigger('transitionend');
                        app.transitionInProgress = false;
                    }
                }, 2000);
            });
        }
    }, 1500);
}

app.fn.markParents = function(wrapper) {
    var parent = wrapper.closest('.w-children').closest('.w-rubric'),
    inputParent,
    labelParent,
    inputsSiblingsWrapper,
    inputsSiblings,
    inputsSiblingsChecked,
    parentPartially;
    while (wrapper.length && parent.length) {
        parent = wrapper.closest('.w-children').closest('.w-rubric');
        if (!parent.length) {
            break;
        }
        inputParent = parent.find('>li>.w-cb input');
        labelParent = inputParent.siblings('label');

        inputsSiblingsWrapper = parent.find('>li>.w-children>.w-rubric>li>.w-cb');
        inputsSiblings = inputsSiblingsWrapper.find('input');
        inputsSiblingsChecked = inputsSiblingsWrapper.find('input:checked');

        parentPartially = inputsSiblingsWrapper.find('label[data-partially]').length ? true : false;

        if (inputsSiblings.length === inputsSiblingsChecked.length) {
            if (parentPartially === true) {
                labelParent.attr('data-partially', 1);
            } else {
                labelParent.removeAttr('data-partially');
            }
            if (!inputParent.is(':checked')) {
                inputParent.prop('checked', true);
                app.fn.saveToSelected(inputParent.val(), 'save');
            }
        } else if (inputsSiblingsChecked.length === 0){
            labelParent.removeAttr('data-partially');
            if (inputParent.is(':checked')) {
                inputParent.prop('checked', false);
                app.fn.saveToSelected(inputParent.val(), 'remove');
            }
        } else {
            labelParent.attr('data-partially', 1);
            if (!inputParent.is(':checked')) {
                inputParent.prop('checked', true);
                app.fn.saveToSelected(inputParent.val(), 'save');
            }
        }
        wrapper = wrapper.parent().closest('.w-rubric');
    }
}

app.fn.changeChildrenState = function(wrapper, action) {
    var children = wrapper.find('.w-rubric');
    if (children.length) {
        children.each(function() {
            app.fn.rubricTrigger($(this), action === 'check' ? 'show' : 'hide');
            var input = $(this).find('>li>.w-cb input');
            if (input.length) {
                input.prop("checked", action === 'check');
                app.fn.saveToSelected(input.val(), action === 'check' ? 'save' : 'remove');
            }
        });
    }
}

app.fn.saveToSelected = function(value, action) {
    if (action === 'save') {
        if (selected.indexOf(value) === -1) {
            selected.push(value);
        }
    } else {
        var index = selected.indexOf(value);
        if (index !== -1) {
            selected.splice(index, 1);
        }
    }
}

app.fn.rubricTrigger = function(clone, initHidden) {
    var action;
    var value = clone.find('input').val();
    var children = $('.w-children[data-parent="' + value + '"]');

    clone.find('>li>.w-cb label').removeAttr('data-partially');

//    var icon = clone.find('>li>.w-icon .icon');
    var icon = clone.find('>li>.w-icon');
    if (initHidden !== null) {
        action = initHidden === 'hide' ? 'hide' : 'show';
    } else {
        action = clone.data('hidden')? 'show' : 'hide';
    }

    if (app.leftPanel) {
        app.leftPanel.canInitPos = false;
    }
    
    if (action === 'show') {
        clone.data('hidden', 0)
        icon
                .removeClass('closed')
                .addClass('opened');
        children.slideDown(500, function() {
            if (app.leftPanel) {
                app.leftPanel.canInitPos = true;
            }
//            app.leftPanel.canInitPos = true;
            $(window).trigger('immediateScrollWindow'); //For refresh rubrics frame height and run recalculate sticky behavior to bottom in app.fn.posLeftPanel
        });
    } else {
        clone.data('hidden', 1);
        icon
                .removeClass('opened')
                .addClass('closed');
        children.slideUp(500, function() {
            if (app.leftPanel) {
                app.leftPanel.canInitPos = true;
            }
//            app.leftPanel.canInitPos = true;
            $(window).trigger('immediateScrollWindow'); //For refresh rubrics frame height and run recalculate sticky behavior to bottom in app.fn.posLeftPanel
        });
    }
};

app.fn.getMapSelected = function(rubrs, chain, level) {
    if (!$.isArray(rubrs)) {
        level++;
        $.each(rubrs, function(i, v) {
            chain.splice(level - 1);
            chain.push(v.id);
            app.fn.getMapSelected(v.data, chain, level);
        });
    } else {
        mapChain.push(chain.slice());
    }
};

app.fn.getOpened = function() {
    $.each(selected, function(i, v) {
        $.each(mapChain, function(i1, v1) {
            var index = v1.indexOf(v);
            if (index !== -1) {
                var toOpen = v1.slice(0, index);
                $.each(toOpen, function(key, val) {
                    if (opened.indexOf(val) === -1) {
                        opened.push(val);
                    }
                });
            }
        });
    });
};

app.fn.initRubrics = function() {
    var root = $('.rubric-place-js');

    var selectedInCookie = Cookies.get('selected');
    if (typeof(selectedInCookie) !== 'undefined') {
        selected = JSON.parse(selectedInCookie);
    }

    app.fn.getMapSelected(rubrics.data, [], 0);
    app.fn.getOpened();

    $.each(mapChain, function(i,v) {
        if (typeof(maxLevel[v[0]]) === 'undefined') {
            maxLevel[v[0]] = v.length;
        } else if (maxLevel[v[0]] < v.length) {
            maxLevel[v[0]] = v.length;
        }
    });

    tmpl = $('.w-rubric.tmpl-js');

    app.fn.addLevelRubric(rubrics.data, 0, null, root);

    var wrapperMaxLevel;
    $('.w-rubric[data-level="1"]').each(function() {
        var value = $(this).find('>li>.w-cb input').val();
        if (typeof(maxLevel[value]) !== 'undefined') {
            var wrapperMaxLevel = $(this).find('.w-rubric[data-level="' + maxLevel[value] + '"]')
            app.fn.markParents(wrapperMaxLevel);
        }
    });
}

//app.fn.initAllRubrics = function() {
//    var root;
//    tmpl = $('.w-rubric.tmpl-js');
//    $.each(rubrics, function(i, v) {
//        root = $('.w-one-js[data-rubric="' + i + '"]');
//        root.append('<div class="btn rubric-name"><div class="inner-btn">' + v.name + '</div></div>');
//        app.fn.addLevelRubric(v.data, 0, null, root, false);
//    });
//}

