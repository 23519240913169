app.fn.replaceBlockComment = function(msg, time) {
    var oldComments = $('.w-comments-js');
    var newComments = $(msg.html);
    var currentTime = new Date().getTime();

    newComments.addClass('loading');
    setTimeout(function() {
        oldComments.replaceWith(newComments);
        setTimeout(function() {
            newComments.removeClass('loading');
        }, 1);
    }, time - currentTime + 300);
}

$('body').on('click', '.submit-comment-js', function(e) {
    e.preventDefault();
    
    var wComments = $('.w-comments-js');
    wComments.addClass('loading');
    var time = new Date().getTime();
    
    var form = $(this).closest('form');
    var gaElm = $(this);
    
    app.fn.triggerGaEvent(gaElm.data());
    
    form.find('input, textarea').each(function() {
        app.fn.removeFormError($(this));
    });
    
    $.ajax({
        url: form.attr('action'),
        data: form.serializeObject()
    }).then(function(msg) {
        if (app.fn.checkMsg({param: [], msg: msg})) {
            if (msg.isError) {
                wComments.removeClass('loading');
                if (typeof(msg['errors']) === 'string') {
                    app.fn.alert({title: err.error, message: msg['errors']});
                } else if (typeof(msg['errors']) === 'object') {
                    try{
                        app.fn.makeFormErrors(form, msg.errors);
                    } catch (err) {
                        app.fn.alert({title: err.error, message: err.err_format});
                    }
                } else {
                    app.fn.alert({title: err.error, message: err.err_format});
                }
            } else {
                app.fn.replaceBlockComment(msg, time);

                app.fn.triggerGaEvent($.extend(gaElm.data(), {gact: 'comment_success'}));
    
                setTimeout(function() {
                    if (typeof(msg.scrollToId) !== 'undefined') {
                        app.fn.scrollToElmId(msg.scrollToId);
                    }
                }, 300);
            }
        }
    });
});

$('body').on('click', '.paginator-js>li>a', function(e) {
    e.preventDefault();
    
    var wComments = $('.w-comments-js');
    wComments.addClass('loading');
    var time = new Date().getTime();
    
    var page = app.fn.getParamQuery($(this).attr('href'), 'page');
    if (page > 1) {
        Cookies.set('commentPage', page, 1/48);    
    } else {
        Cookies.remove('commentPage');
    }
    
    var data = {nextPage: true};
    data[app.csrf.name] = app.csrf.value;
    $.ajax({
        url: $(this).attr('href'),
        data: data
    }).then(function(msg) {
        if (app.fn.checkMsg({param: [], msg: msg})) {
            if (msg.isError) {
                wComments.removeClass('loading');
                if (msg.redirect) {
                    location.href = msg.redirect;
                } else {
                    app.fn.alert({title: err.error, message: err.err_format});
                }
            } else {
                app.fn.replaceBlockComment(msg, time);
                setTimeout(function() {
                    app.fn.scrollToElmId('comments');
                }, 300);
            }
        }
    });
});