/*Uploading
Usage:elm.upl({
	name:elm.attr('data-nm'),		name of created input[type="file"] if is omitted, than assumed, that input[type="file"] exists
	onStart:function(){},
	onDone:function(){},
	not_remove_input:true			if set, than after end of execution input[type="file"] don't removed
});

After loading file procedure must return json encoded array
	'isError'	if TRUE need 'message' and nothing more
	'src'		url of loaded file
	'data'		name of input[type="file"]
	'success'	name of function, that executed after end of loading file
*/
    $.fn.upl = function(options){
            return this.each(function(e){
                    var el = $(this);
                    var form = el.closest('form');
                    var path = (typeof(options.path) == "undefined")?form.attr("action"):option.path;

                    function click_change(){
                            el.off('click').on('click',function(e){
                                    if (el.prop("tagName") == "A") e.preventDefault();
                                            input_file.click();
                            });
                            input_file.off('change').on('change',function(e){
                                    if(typeof(options.onStart) == 'function'){
                                            options.onStart();
                                    }
                                    $('body').append('<iframe id="target" name="target" style="display:none" />');
                                    form.attr('action',path);
                                    form.attr('target','target');
                                    form.submit();
                            });
                    }

                    if(typeof(options.name) != "undefined"){
                            if (!$('input[name="'+options.name+'"]').length){
                                    el.after('<input type="file" name="'+options.name+'" style="position: absolute;left:-9999px">');
                            }
                            var input_file = $('input[name="'+options.name+'"]');
                    } else {
                            var input_file = el.siblings('input[type="file"]');
                    }

                    if (typeof(options.act) != "undefined"){
                            if (options.act == "refresh"){
                                    name = input_file.attr('name');
                                    if($('input[name="'+name+'"]').val() != ""){
                                            input_file.replaceWith(input_file.val('').clone(false));
                                    }
                                    var input_file = $('input[name="'+name+'"]');
                                    click_change();
                            }
                    } else {
                            click_change();
                            form.off('submit').on('submit',function(e){
                                    $('#target').load(function(){
                                            form.removeAttr('target');
                                            if(typeof(options.onDone) == 'function'){
                                                    options.onDone();
                                            }
                                            var msg = $('#target').contents().find('#json').html();
                                            try{
                                                    obj=$.parseJSON(msg);
                                            } catch(e) {
                                                    $.err_alert({title:err.error,message:err.err_format});
                                                    return;
                                            }
                                            if(check_msg({msg:obj,param:['src','data','success']})){
                                                    window[obj['success']](obj);
                                                    $('#target').unbind('load');
                                                    $('#target').remove();
                                                    if(typeof(options.not_remove_input) == "undefined"){
                                                            $('input[name="'+obj.data+'"]').remove();
                                                    }
                                            }
                                    });
                            });
                    }
            });
    }
    