
app.templates['login'] = require('./loginTemplate.html').default;
app.templates['register'] = require('./registerTemplate.html').default;
app.templates['remember'] = require('./rememberTemplate.html').default;


$('.auth-js').on('click', function(e) {
    e.preventDefault();
    app.fn.alert({
        title: '',
        message: app.templates.login,
        class: 'login'
    });
});

