//real load event can occur when scripts wait until they are loading
app.fn.windowLoad = function() {
    app.scroll = $(window).scrollTop();
    app.main_menu['height'] = $('nav.header').height();
    $(window).trigger('scrollWindow');
};

$(window).on('load', function() {
    app.fn.windowLoad();
});

if (app.window.isLoaded === true) {
    app.fn.windowLoad();
}
