    app.alertDefaultSettings = {
        title: '',
        message: '',
        info: false,
        class: '',
        close: false,
        open: false,
        beforeClose: false,
        confirm: false,
        function_yes: false,
        duration: 300,
        modal: true,
        closeOnEscape: true,
        buttons: false,
        minHeight:0,
        resizable:false,
        draggable: false,
        position: false,
        classAnimation: 'from-up',
        onClose: false
    };

    app.fn.setAlertParams = function(param) {
        $.each(app.alertDefaultSettings, function(i, v) {
            param[i] = param[i] || app.alertDefaultSettings[i];
        });

        param['dialogClass'] = (param.info ? 'ui-info-dialog' : 'ui-error-dialog') 
                + ' alert ' + param.class + ' ' +  param.classAnimation;

        var btnCloseDefault = {
            text: err.close,
            click: function(){$(this).dialog("close");},
            class:'btn ' + (param.info ? 'btn-green' : 'btn-red') + ' btn-round'
        };
        var btnConfirmDefault = {
            text:err.confirm,
            click:function(){
                $(this).dialog("close");
                if (param.function_yes !== false) {
                    param.function_yes(param.elm);
                }
            },
            class:'btn ' + (param.info ? 'btn-green' : 'btn-red') + ' btn-round'
        };

        if (param.buttons !== false) {
            param.buttons['Close'] = {}
            $.each(btnCloseDefault, function(i, v) {
                param['buttons']['Close'][i] = v || btnCloseDefault[i];
            });
            if (param.confirm !== false) {
                param['buttons']['Confirm'] = {};
                $.each(btnConfirmDefault, function(i, v) {
                    param['buttons']['Confirm'][i] = v || btnConfirmDefault[i];
                });
            }                    
        }

        if (param.open === false) {
            param['open'] = function( event, ui ) {
                app.alert = $(event.target).closest('.ui-dialog');
                app.alert.find('.ui-dialog-titlebar-close')
                        .html('<span aria-hidden="true">×</span>')
                        .addClass('close icon-close')
                        .attr('title', err.close);
                setTimeout(function() {
                    app.alert.focus();
                }, param.duration);
                app.alert.addClass('active');
            };
        }

        if (param.beforeClose === false) {
            param['beforeClose'] = function(event,ui){
                app.fn.removeShadow();
                app.alert.removeClass('active');
                app.fn.waitElmExists(function() {
                    $('#alert').remove();
                    app.alert = false;
                    if (app.alertsArr.length > 0) {
                        param = app.alertsArr.shift();
                        app.fn.alert(param);
                    }
                }, '.shadow');
                if(typeof(param.onClose) === 'function'){
                    param.onClose();
                }
            }
        }

        if (param.position === false) {
            param['position'] = {
                using: function(pos,elms){}            
            };
        }
        
        return param
    }

    app.fn.alert = function(param) {
        param = app.fn.setAlertParams(param);
        var dfd = new $.Deferred();
        if (app.alert !== false) {
            var tmpParam = app.alert.param;
            app.fn.waitElmExists(function() {
                app.alertsArr.push(tmpParam);
                dfd.resolve();
            }, '#alert');
            $('#alert').dialog("close");
        } else {
            dfd.resolve();
        }
        dfd.then(function() {
            app.fn.makeShadow();
            $('body').append('<div id="alert" title="' + param.title + '">' + param.message + '<div autofocus=""></div></div>');
            $('#alert').dialog(param);
            app.alert['param'] = param;
        });
    };

