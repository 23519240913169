var searchOverlay = $('.search-overlay');
var searchInput = $('.search-input-js');
var searchOpener = $('.search-opener-js');

searchInput.on('keypress', function(e) {
    if (e.which === 13) {
        location.href = app.base_url + 'search?search=' + $(this).val();
    }
});

searchOpener.on('click', function(e) {
    e.preventDefault();
    searchOverlay.addClass('active');
});

searchOverlay.find('.close').on('click', function(e) {
    e.preventDefault();
    searchOverlay.toggleClass('active');
});
