
$(document).on('click', '.change-tab-js', function(e) {
    e.preventDefault();
    var form = $(this).closest('form');
    var target = $(this).data('target')
    var formToReplace = $(app.templates[target]);

    formToReplace.addClass('inactive');
    form.addClass('inactive');
    
    setTimeout(function() {
        form.replaceWith(formToReplace);
        setTimeout(function() {
            formToReplace.removeClass('inactive');
        }, 10);
    }, 300);
});

$(document).on('click', '.login-js, .register-js', function(e) {
    e.preventDefault();
    var form = $(this).closest('form');
    var dataTarget = form.data('target');
    if (dataTarget === 'app.alert') {
        app.alert.addClass('wbg');
    }
    form.find('input').each(function() {
        app.fn.removeFormError($(this));
    });
    
    if (typeof(form.data('gcat')) !== 'undefined' && typeof(form.data('gact-try')) !== 'undefined' && typeof(form.data('glabel')) !== 'undefined') {
        var gaEventData = {
            gcat: form.data('gcat'),
            gact: form.data('gact-try'),
            glabel: form.data('glabel')
        }
        app.fn.triggerGaEvent(gaEventData);
    }    
    
    var data = form.serializeObject();
    data[app.csrf.name] = app.csrf.value;
    $.ajax({
        url: form.attr('action'),
        data: data
    }).then(function(msg) {
        if (app.fn.checkMsg({
            param: [],
            msg: msg
        })) {
            if (msg.isError) {
                if (typeof(msg['errors']) === 'string') {
                    app.fn.alert({title: err.error, message: msg['errors']});
                } else if (typeof(msg['errors']) === 'object') {
                    try{
                        app.fn.makeFormErrors(form, msg.errors);
                    } catch (err) {
                        app.fn.alert({title: err.error, message: err.err_format});
                    }
                } else {
                    app.fn.alert({title: err.error, message: err.err_format});
                }
            } else {
                if (dataTarget === 'app.alert') {
                    
                    if (typeof(form.data('gcat')) !== 'undefined' && typeof(form.data('gact-success')) !== 'undefined' && typeof(form.data('glabel-success')) !== 'undefined') {
                        var gaEventData = {
                            gcat: form.data('gcat'),
                            gact: form.data('gact-success'),
                            glabel: form.data('glabel-success')
                        }
                        app.fn.triggerGaEvent(gaEventData);
                    }    
                    
                    if (typeof(msg['redirectLink']) !== 'undefined') {
                        location.href = msg['redirectLink'];
                    }
                }
            }
        }
    }).always(function() {
        if (dataTarget === 'app.alert') {
            app.alert.removeClass('wbg');
        }
    }).fail(function(err) {
        console.log('Error!!! ' + err.status + ': ' + err.statusText);
    });
});