app.fn.waitElmExists = function(func, params) {
    setTimeout(function waitClose() {
        if ($(params).length) {
            setTimeout(waitClose, 50);
        } else {
            var tmp = {f: func};
            tmp.f(params);
        }
    }, 50);
}

$.fn.serializeObject = function () {
  var formData = {};
  var formArray = this.serializeArray();

  for(var i = 0, n = formArray.length; i < n; ++i)
    formData[formArray[i].name] = formArray[i].value;

  return formData;
};

app.fn.makeShadow =  function(){
    if (app.shadow === false) {
        $('body').append('<div class="shadow" style="opacity:0"></div>');
        app.shadow = $('.shadow');
    }
    app.shadow.height($(document).height());
    app.shadow.animate({opacity:1},300);
}

app.fn.removeShadow = function(){
    if (app.shadow !== false) {
	app.shadow.animate({opacity:0}, 300, function(){
            app.shadow.remove();
            app.shadow = false;
	});
    }
}

app.fn.checkMsg = function(params){
    if(typeof(params.msg) !== 'object'){
        app.fn.alert({title: err.error, message: err.err_format});
        return false;
    } else {
        if (typeof(params.msg.isError) === 'undefined'){
            app.fn.alert({title: err.error, message: err.err_format});
            return false;
        } else {
            var res = true;
            $.each(params.param, function(i, v){
                if(typeof(params.msg[v]) === 'undefined'){
                    res = false;
                    app.fn.alert({title: err.error, message: err.err_format});
                    return;
                }
            });
            return res;
        }
    }
}

app.fn.removeParam = function(str,param){
    var len_param = param.length;
    var regexp = new RegExp(param);
    if(!str.match(regexp)){
        return str;
    }
    var tmp = str.replace(base_url,'').split('/');
    var res = [];
    $.each(tmp,function(i,v){
        if(v.substr(0,len_param) != param){
            res.push(v);
        }
    });
    return base_url + res.join('/');
}

app.fn.getParam = function(str,param){
    param += '_';
    var res = -1;
    var len_param = param.length;
    var regexp = new RegExp(param);
    if(!str.match(regexp)){
        return res;
    }
    var tmp = str.replace(base_url,'').split('/');
    $.each(tmp,function(i,v){
        if(v.substr(0,len_param) == param){
            res = v;
            return;
        }
    });
    res = res.replace(regexp,'');
    return res;
}

app.fn.getParamQuery = function($str, $param) {
    var result = false;
    var queryString = $str.split('?').slice(1).join('');
    if (queryString === '') {
        return result;
    }
    var queryArr = queryString.split('&');
    $.each(queryArr, function(i, v) {
        if (v.indexOf($param) !== -1) {
            result = v.split('=').slice(1).join('');
        }
    });
    if (result === '') {
        return false;
    }
    return result;
};

app.fn.scrollToElmId = function(id) {
    var elm = $('#' + id);
    if (elm.length) {
        var offset = Math.round($(window).height()/3);
        $("html, body").animate({
            scrollTop: elm.offset().top - offset
        }, 300);
    }
};

app.fn.toggleSmallMenu = function(burger, smallMenu) {
    if (burger.hasClass('is-active')) {
        app.fn.hideSmallMenu(burger, smallMenu);
    } else {
        app.fn.showSmallMenu(burger, smallMenu);
    }
}

app.fn.hideSmallMenu = function(burger, smallMenu) {
    if (burger.hasClass('is-active')) {
        burger.removeClass('is-active');
        if (burger.data('max-height')) {
            smallMenu.removeClass('showed');
            smallMenu.slideUp(300, function() {
                
            });
        } else {
            smallMenu.removeClass('showed');
        };
    }
    if ($('.menu-shadow').length){
        $('.menu-shadow').remove();
    }
}

app.fn.showSmallMenu = function(burger, smallMenu) {
    if (!burger.hasClass('is-active')) {
        burger.addClass('is-active');
        if (burger.data('max-height')) {
        //     smallMenu.css({left: '-99999px', display: 'block'})
        //     var maxHeight = smallMenu.prop('scrollHeight') + 2;
        //     if (maxHeight > app.window.height - app.main_menu.height) {
        //         maxHeight = app.window.height - app.main_menu.height;
        //     }
        //     smallMenu.removeAttr('style');
        //     smallMenu.css({'max-height': maxHeight + 'px'});
            smallMenu.addClass('showed').slideDown(300);
        } else {
            smallMenu.addClass('showed');
        }
        if (burger.data('close-button') && !burger.data('close-init')) {
            burger.data('close-init', '1');
            $(burger.data('close-button')).on('click', function(e) {
                e.preventDefault();
                app.fn.hideSmallMenu(burger, smallMenu);
            });
        }
        if (burger.data('shadow')) {
            $('body').prepend('<div class="menu-shadow"></div>');
            $('.menu-shadow').on('click', function() {
                app.fn.hideSmallMenu(burger, smallMenu);
            });            
        }
    }
}

app.fn.triggerGaEvent = function(data) {
    var gaParams = {};
    $.each(['gcat', 'gact', 'glabel', 'callback'], function(i, v) {
        if (typeof(data[v]) !== 'undefined') {
            gaParams[v] = data[v];
        }
    });
    if (Object.keys(gaParams).length !== 0) {
        if (typeof(ga) === 'function') {
            $(window).trigger('gaEvent', gaParams);
        } else {
            $(window).on('gaLoaded', function() {
                $(window).trigger('gaEvent', gaParams);
            });
        }
    }
    
}