var tmpl = $('.tmpl-comment-js').clone();
tmpl.addClass('hided');
tmpl.find('input, textarea').each(function() {
    app.fn.removeFormError($(this));
});
tmpl.find('input[name="mood"]').first().prop('checked', true);
var btn = tmpl.find('.btn.action-js');
var btnTmpl = btn.clone();
btnTmpl.removeClass('btn-red submit-comment-js').addClass('btn-blue undo-js').text('Отменить');
btn.after(btnTmpl);
tmpl.append('<div class="w-form-error"><input type="hidden" name="to_comment"/>')
tmpl.append('<div class="w-form-error"><input type="hidden" name="to_level"/>')

$('body').on('click', '.comment-answer-js', function(e) {
    e.preventDefault();
    if (!$(this).siblings('form').length) {
        var form = tmpl.clone();
        var id = $(this).closest('.w-comment-js').data('id');
        var levelId = $(this).closest('.w-level-comment-js').data('id');
        var rand = Math.round(10000 + Math.random() * (99999 - 10000));
        form.find('input[name="mood"], input[name="rating"]').each(function() {
            var newId = $(this).attr('id') + rand;
            $(this).attr('id', newId);
            $(this).siblings('label').attr('for', newId);
        });
        form.find('input[name="to_comment"]').val(id);
        form.find('input[name="to_level"]').val(levelId);
        $(this).before(form);
        setTimeout(function() {
            form.removeClass('hided');
        }, 10);
        $(this).hide();
        $(this).siblings('.admin-js').hide();
    }
});

$('body').on('click', '.undo-js', function(e) {
    e.preventDefault();
    var form = $(this).closest('form');
    
    form.addClass('hided');
    setTimeout(function() {
        form.siblings('.comment-answer-js').show();
        form.siblings('.admin-js').show();
        form.remove();
    }, 300);
});