app.fn.makeFormErrors = function(form, errors) {
    $.each(errors, function(i, v) {
        var input = form.find('[name="' + i + '"]');
        var errorWrapper = input.closest('.w-form-error');
        var errorHtml = '<div class="form-error">' + v + '</div>';
        var formError;
        input.addClass('error-input');
        if (errorWrapper.length) {
            errorWrapper.append(errorHtml);
            formError = errorWrapper.find('.form-error');
        } else {
            input.after(errorHtml);
            formError = input.siblings('.form-error');
        }
        setTimeout(function() {
            formError.addClass('active');
        }, 10);
    });
}

app.fn.removeFormError = function(elm) {
    var formError;
    var input = elm.siblings('.form-error');
    var wrapper = elm.closest('.w-form-error');
    if (input.length) {
        formError = input;
    } else if (wrapper.length) {
        formError = wrapper.find('.form-error');
    }
//    var formError = elm.siblings('.form-error') || elm.closest('.w-form-error');
    if (typeof(formError) !== 'undefined') {
        elm.removeClass('error-input');
        formError.removeClass('active');
        setTimeout(function() {
            formError.remove();
        }, 300);
    }
}

$("body").on('click, focus', '.error-input', function() {
    app.fn.removeFormError($(this));
});

$("body").on('click', '.form-error', function() {
//    app.fn.removeFormError($(this).siblings('input, textarea'));
    app.fn.removeFormError($(this).parent().find('input, textarea'));
});

