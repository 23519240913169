$.ajaxSetup({
    type: 'POST',
    dataType: "json",
    error: function(){
        if(typeof($(this) === 'object')){
            if(typeof($(this)[0]) === 'object'){
                if(typeof($(this)[0].url) === 'string'){
                    if(
                            ($(this)[0].url.indexOf(app.base_url) > -1)
                            || (!/^http[s]*?:\/\//.test($(this)[0].url))
                    ){
                        app.fn.alert({
                            title:err.error,
                            message:err.cont
                        });
                    }
                }
            }
        }
    }
});
