 app.fn.calcTextareaDataHeight = function(elm) {
    var countRows = elm.attr('rows');
    if (typeof(countRows) === 'undefined') {
        countRows = 3;
    }
    var limitHeight = 
            parseInt(elm.css('padding-top')) 
            + parseInt(elm.css('padding-bottom'))
            + parseInt(elm.css('line-height')) * countRows;
    elm.data('limitHeight', limitHeight);
 };

$(document).on('focus', 'textarea.autosize-js', function(e){
    var elm = $(this);
    elm.trigger('keyup');
});

$(document).on('keyup', 'textarea.autosize-js', function(e){
    //thx javierjulio/textarea-autosize
    if (typeof($(this).data('limitHeight')) === 'undefined') {
        app.fn.calcTextareaDataHeight($(this));
    }
    var limitHeight = $(this).data('limitHeight');
    var currentScrollPosition = $(window).scrollTop();
    if (this.scrollHeight > 81) {
        $(this).height(0).height(this.scrollHeight - 15);
        $(window).scrollTop(currentScrollPosition);
    }
});

