app.fn.initShowMore = function() {
    $('.show-more-js').on('click', function(e) {
        e.preventDefault();
        var el = $(this);
        el.hide();
        el.before('<div class="loading-more wbg"></div>');
        var data = {page: 'next'};
        data[app.csrf['name']] = app.csrf['value'];
        $.ajax({
            url: el.attr('href'),
            data: data
        }).then(function(msg) {
            if (app.fn.checkMsg({param: [],msg: msg})) {
                if (msg.isError) {
                    app.fn.alert({
                        title: err.error,
                        message: msg.message
                    });
                } else {
                    msg['html'] = '<div class="loaded-more">' + msg['html'] + '</div>';
                    var newContent = $(msg['html']);
                    $('.w-more').before(newContent);
                    app.LazyLoad.update();
                    setTimeout(function() {
                        $('.loaded-more').addClass('active');
                    }, 0);
                }
            }
        }).always(function() {
            $('.loading-more').remove();
            el.show();
        });
    });

}

app.fn.initShowMore();

