var deferInterval = 100;

setTimeout(function() {
    $.ajax({
        type: 'GET',
        url: '/js/social-likes.min.js',
        dataType: "script",
        timeout: 2 * 1000,
        cache: true,
        error: function() {}
    })
    .then(
        function() {
            $(window).trigger('socialLikesLoaded');
        },
        function(err) {
            console.log('Can\'t load social-like');
        }
    );
}, deferInterval);
