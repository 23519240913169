//@deprecated - will be rem-ed
var submenu = $('ul.main-menu .has-submenu');

submenu.each(function() {
    var currentSubmenu = $(this);
    var headingActive = currentSubmenu.find('.heading-js.active');
    var detailActive = currentSubmenu.find('.detail-js.active');
    var activeId = headingActive.data('detail');
    var timer;
    
    currentSubmenu.find('a.heading-js').on('mouseenter', function() {
        var enteredHeading = $(this);
        var newHeadingActiveId = enteredHeading.data('detail');
        if (newHeadingActiveId !== activeId) {
            clearTimeout(timer);
            timer = setTimeout(function() {
                if (activeId !== newHeadingActiveId) {
                    var newHeadingActive = enteredHeading;
                    var newDetailActive = currentSubmenu.find('.detail-js[data-detail="' + newHeadingActiveId + '"]');
                    headingActive.addClass('out');
                    detailActive.addClass('out');
                    newHeadingActive.addClass('active');
                    setTimeout(function() {
                        headingActive.removeClass('active out');
                        detailActive.removeClass('active out');
                        newDetailActive.addClass('active');
                        headingActive = newHeadingActive;
                        detailActive = newDetailActive;
                        activeId = newHeadingActiveId;
                    }, 300);
                }
            }, 100);
        }
    });
});


