if (!app.debug) {

  var deferInterval = 100;

//moved to footer
//test
//var code = 'UA-76429420-1'
//real
//var code = 'UA-66193064-1';


  setTimeout(function() {
    $.ajax({
        type: 'GET',
        url: 'https://www.google-analytics.com/analytics.js',
        dataType: "script",
        timeout: 2 * 1000,
        cache: true,
        error: function() {}
    })
    .then(
        function() {
            console.log('GA loaded');
            window.ga = window.ga || function() {
                (ga.q = ga.q || []).push(arguments)
            };
            ga.l = +new Date;
            ga('create', gaCode, 'auto');
            ga('send', 'pageview');
        },
        function(err) {
            console.log('Can\'t load GA');
        }
    )
    .then(function() {
        $(window).on('gaEvent', function(e, params) {
            
            var gaParams = {};
            
            if (typeof(params['gcat']) !== 'undefined') {
                gaParams['eventCategory'] = params['gcat'];
            }
            if (typeof(params['gact']) !== 'undefined') {
                gaParams['eventAction'] = params['gact'];
            }
            if (typeof(params['glabel']) !== 'undefined') {
                gaParams['eventLabel'] = params['glabel'];
            }
            if (typeof(params['callback']) !== 'undefined') {
                gaParams['hitCallback'] = params['callback'];
            }
            
            ga('send','event', gaParams);
        });
    })
    .then(function() {
        $(window).trigger('gaLoaded');
    });
  }, deferInterval);

}
